import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface ModalState {
  modalConnect: any
  modalBuyNow: { toggle: boolean; dataModal: any }
}

const initialState: ModalState = {
  modalConnect: { toggle: false, dataModal: null },
  modalBuyNow: { toggle: false, dataModal: null },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setModalConnect, (state, { payload }) => {
      state.modalConnect = payload
    })
    .addCase(actions.setModalBuyNow, (state, { payload }) => {
      state.modalBuyNow = payload
    }),
)
