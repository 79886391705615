import { ArrowForwardIcon, Flex } from '@pancakeswap/uikit'
import InputCopy from 'components/CoppyItem/InputCopy'
import { UserDataResp } from 'config/types/Authentication'
import { WalletType } from 'config/types/Wallet'
import { useTranslation } from 'contexts/Localization'
import { formatCode } from 'helpers/CommonHelper'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import BaseButton from 'widgets/BaseButton'
import Accordion from './Accordion'
import { MenuEntry, MenuLink, MenuSubEntry } from './MenuEntry'
import { MenuEntry as MenuEntryTypes } from './types'

const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 110px 16px 32px;
  z-index: 11;
  overflow: auto;
  transition: 0.25s;
  transform: ${({ visible }) => (visible ? 'translateX(0)' : 'translateX(100%)')};
  background: #070f0f;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul li {
    list-style: none;
  }
`

const MenuActionsMobile = styled.div`
  margin-top: 24px;
  position: relative;

  > div:not(:first-child) {
    margin-top: 16px;
  }
`

const UserBox = styled(Flex)`
  img {
    width: 50px;
    height: 50px;
    margin-right: 16px;
  }

  > div {
    svg {
      fill: #969999;
      width: 16px;
      height: 16px;
    }

    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    .address-copy {
      padding: 0;
      max-width: 110px;

      .wrapper-input {
        padding: 0;

        .ant-input-affix-wrapper {
          padding: 0;
        }

        input.ant-input {
          color: #969999;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }
`

interface Props {
  visible: boolean
  links: MenuEntryTypes[]
  accountEllipsis?: string
  toggleWallet: () => void
  toggleMenu: () => void
  userData: UserDataResp
  onSignOut: () => void
  myWallet: WalletType
}

const MobileSlider = ({
  visible,
  links,
  accountEllipsis,
  toggleWallet,
  toggleMenu,
  userData,
  onSignOut,
  myWallet,
}: Props) => {
  const router = useRouter()
  const { t } = useTranslation()
  return (
    <>
      <Wrapper visible={visible}>
        <ul>
          {userData && (
            <UserBox as="li" alignItems="center" justifyContent="space-between">
              <Flex>
                <Flex as="img" src={userData?.avatar} alt="" alignItems="center" justifyContent="center" />
                <Flex flexDirection="column">
                  <p>{userData?.fullName}</p>

                  <InputCopy
                    className="address-copy"
                    value={myWallet?.address}
                    displayValue={formatCode(myWallet?.address, 5, 5)}
                  />
                </Flex>
              </Flex>

              <ArrowForwardIcon
                width="32px"
                onClick={() => {
                  router.push('/profile')
                  toggleMenu()
                }}
              />
            </UserBox>
          )}

          {links.map((entry: any) => {
            const paths = router.pathname.split('/').map((path) => `/${path}`)
            const isActive = entry.actives.includes(paths[1])
            if (entry.items) {
              return (
                <Accordion key={`menu-${entry.label}`} label={entry.label} isActive={isActive}>
                  {entry.items.map((subMenu) => {
                    return (
                      <MenuSubEntry
                        key={`sub-${subMenu.label}`}
                        className={paths.includes(subMenu.href || '') ? 'active' : ''}
                        onClick={toggleMenu}
                      >
                        <MenuLink href={subMenu.href}>{subMenu.label}</MenuLink>
                      </MenuSubEntry>
                    )
                  })}
                </Accordion>
              )
            }
            return (
              <MenuEntry
                key={`menu-${entry.label}`}
                className={paths.includes(entry.href || '') ? 'active' : ''}
                onClick={toggleMenu}
              >
                <MenuLink href={entry.href}>{t(entry.label)}</MenuLink>
              </MenuEntry>
            )
          })}

          {userData && (
            <>
              {/* <MenuEntry onClick={toggleMenu}>
                <MenuLink href="/notification">{t('Notification')}</MenuLink>
              </MenuEntry> */}

              <MenuEntry
                onClick={() => {
                  onSignOut()
                  toggleMenu()
                }}
              >
                <MenuLink href="#">{t('Sign out')}</MenuLink>
              </MenuEntry>
            </>
          )}
        </ul>

        <MenuActionsMobile>
          {/* <LocalizeSelector /> */}
          {/* <BaseButton
            className="open-app-btn"
            onClick={() => {
              router.push('/connect')
            }}
          >
            <div>{accountEllipsis || 'OPEN APP'}</div>
          </BaseButton> */}

          {!userData && (
            <>
              <BaseButton className="auth-btn sign-in" onClick={() => router.push('/sign-in')}>
                {t('Sign in')}
              </BaseButton>
              <BaseButton className="auth-btn sign-up" mt="16px" secondary onClick={() => router.push('/sign-up')}>
                {t('Sign up')}
              </BaseButton>
            </>
          )}
        </MenuActionsMobile>
      </Wrapper>
    </>
  )
}
export default MobileSlider
